import React from "react";
import { 
  Seo,
  Section,
  Container,
  Zoom
} from "@components";
import { PageLayout } from "../../../components/site/layout/page-layout";
import { QuickstartGrid } from "../../../components/site/quickstarts";
import { 
  AppAttestationImage,
  MobileAppIntegrationImage,
  DeviceIntegrityImage,
  ChannelIntegrityImage, 
  CredentialsIntegrityImage, 
  IconsPhoneImage, 
  ProductBannerImage, 
  ServiceIntegrity,
  EasyImage
} from "../../../components/site/product";

import { Link } from "gatsby";

const ProductOverviewPage = ({ location }) => {
  return (
    <PageLayout pathname={location.pathname}>
      {/* Big Brand start */}
      <div className="bg-[#122331] w-full min-h-[327px] h-[48vw] max-h-[474px]">
        <div className="container flex ">
          <div className="w-full md:w-1/2 relative">
            <h1 className="text-[35px] md:text-[58px] leading-[58px] font-black self-center text-center tracking-tight mt-[60px] md:mt-[133px] md:ml-[-53px] text-[#acafb3]">
              Runtime Application <br></br>Self Protection <br></br><span>(RASP)</span>
            </h1>
            <hr className="border-[3.3px] bg-[#b91684] border-[#b91684] w-[40%] m-auto mt-[-17px] md:ml-[27%] ml-[30%]" />
          </div>
          <div className="hidden md:block w-1/2 ml-[-20px] mt-[44px] not-prose">
            {ProductBannerImage}
          </div>
        </div>
      </div>
      {/* Big Brand end */}

      {/* Blogs start */}
      <div className="container">
        <div className="pt-[6rem]">
          {/* Title start */}
          <div className="text-center not-prose px-5">
            <p className="text-[24px] md:text-[42px] font-black  not-prose">Six Ways Approov Secures Mobile Apps</p>
            <p className="text-[19px] relative left-[-5px] top-[-5px]">
              Approov provides the only comprehensive run-time security solution for mobile apps and their APIs, unified across Android, iOS, and HarmonyOS.
            </p>
          </div>
          {/* Title end */}
          {/* Blog contents start */}
          <div className="flex justify-center flex-col md:flex-row">
            {/* Left side contents start */}
            <div className="sm:w-full md:w-1/5 not-prose mt-[30px] md:hidden block text-center">
              {IconsPhoneImage}
            </div>
            <div className="sm:w-full md:w-2/5 mt-[15px] text-center sm:text-left">
              <div>
                <div className="flex justify-center items-start pt-[35px]">
                  <div className="hidden md:block w-[32%] pl-[20px]">
                    {DeviceIntegrityImage}
                  </div>
                  <div className="w-full md:w-[68%] px-[20px] ">
                    <div className="flex justify-center md:flex-none md:block">

                      <Link to="/mobile-app-security/rasp/app-attestation/" className=" no-underline">
                        <strong className="text-[16.68px] hover:text-[#86b146] text-black leading-[20px]">App Attestation</strong>
                      </Link>
                    </div>
                    <div className="text-[19px] leading-[24px] not-prose">
                      Approov definitively attests to the authenticity of your app and the device on which it is running.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex justify-center items-start pt-[35px]">
                  <div className="hidden md:block w-[32%] pl-[20px]">
                    {EasyImage}
                    {/* {DeviceIntegrityImage} */}
                  </div>
                  <div className="w-full md:w-[68%] px-[20px] ">
                    <div className="flex justify-center md:flex-none md:block">
                      <Link to="/mobile-app-security/rasp/realtime-threat-intelligence/" className=" no-underline">
                        <strong className="text-[16.68px] hover:text-[#86b146] text-black leading-[20px]">Real Time Threat Intelligence</strong>
                      </Link>
                    </div>
                    <div className="text-[19px] leading-[24px] not-prose">
                      Approov gives you direct real time insight into your deployed apps, the environments they are running in and any threats being actively defended.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex justify-center items-start pt-[35px]">
                  <div className="hidden md:block w-[32%] pl-[20px]">
                    {CredentialsIntegrityImage}
                  </div>
                  <div className="w-full md:w-[68%] px-[20px] ">
                    <div className="flex justify-center md:flex-none md:block">
                      <Link to="/mobile-app-security/rasp/dynamic-cert-pinning/" className=" no-underline">
                        <strong className="text-[16.68px] hover:text-[#86b146] text-black leading-[20px]">Dynamic Certificate Pinning</strong>
                      </Link>
                    </div>
                    <div className="text-[19px] leading-[24px] not-prose">
                      Approov's blocks Man-in-the-Middle (MitM) attacks and enables secure over-the-air instant pin updates without service disruptions.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Left side contents end */}
            <div className="sm:w-full md:w-1/5 not-prose mt-[67px] md:block hidden">
              {IconsPhoneImage}
            </div>
            {/* Right side contents start */}
            <div className="sm:w-full md:w-2/5 mt-[15px] text-center sm:text-left">
              <div>
                <div className="flex justify-center items-start pt-[35px]">
                  <div className="w-full md:w-[68%] px-[20px] ">
                    <div className="flex justify-center md:flex-none md:block">
                      <Link to="/mobile-app-security/rasp/api-security/" className=" no-underline">
                        <strong className="text-[16.68px] hover:text-[#86b146] text-black leading-[20px]">Runtime Secrets Protection</strong>
                      </Link>
                    </div>
                    <div className="text-[19px] leading-[24px] not-prose">
                      Approov allows removal of hardcoded API keys and secrets from your app. These can be updated instantly across installed apps.
                    </div>
                  </div>
                  <div className="hidden md:block w-[32%] pr-[20px] ">
                    {ServiceIntegrity}
                  </div>
                </div>
              </div>
              <div>
                <div className="flex justify-center items-start pt-[35px]">
                  <div className="w-full md:w-[68%] px-[20px]">
                    <div className="flex justify-center md:flex-none md:block">
                      <Link to="/mobile-app-security/rasp/api-security/" className=" no-underline">
                        <strong className="text-[16.68px] hover:text-[#86b146] text-black leading-[20px]">API Security</strong>
                      </Link>
                    </div>
                    <div className="text-[19px] leading-[24px] not-prose">
                      Approov protects your backend APIs from API abuse, credential stuffing, fake botnet registrations, and <Link href="https://approov.io/blog/the-rise-of-ddos">DDoS attacks</Link>.
                    </div>
                  </div>
                  <div className="hidden md:block w-[32%] pr-[20px]">
                    {ChannelIntegrityImage}
                  </div>
                </div>
              </div>
              <div>
                <div className="flex justify-center items-start pt-[35px]">
                  <div className="w-full md:w-[68%] px-[20px] ">
                    <div className="flex justify-center md:flex-none md:block">
                      <Link to="/mobile-app-security/rasp/app-shielding/" className=" no-underline">
                        <strong className="text-[16.68px] hover:text-[#86b146] text-black leading-[20px]">App Shielding</strong>
                      </Link>

                    </div>
                    <div className="text-[19px] leading-[24px] not-prose">
                      Approov detects unsafe operation environments on the client device, such as <Link href="https://approov.io/blog/root-and-jailbreak-to-ban-or-not-to-ban">rooted/jailbroken devices</Link>, debuggers/emulators and malicious frameworks.
                    </div>
                  </div>
                  <div className="hidden md:block w-[32%] pr-[20px]">
                    {AppAttestationImage}
                  </div>
                </div>
              </div>
            </div>
            {/* Right side contents start */}
          </div>
          {/* Blog contents end */}
          <hr className="border-[3.3px] bg-[#b91684] border-[#b91684] w-[96%] m-auto mt-[21px]" />
        </div>
      </div>
      {/* Blogs end */}

      {/* Blog Mobile App Attestation start */}
      <Section>
        <Container>
          <div className="md:flex px-[10px] md:px-[29px] mt-[24px] gap-8">
              <div className="w-full md:w-[28%]">
                <h2 className="md:text-left text-center mt-0 font-bold text-[24px] md:text-[31px] text-[#1b3d57] leading-[36px] m-auto">Mobile App Integration</h2>
              </div>
              <div className="w-full md:w-[72%] text-center sm:text-left">
                <p className="m-0 text-[19px] leading-normal mb-[2rem]">
                  Adding Approov to your iOS or Android app is easy. A wide range of platforms are supported using our Quickstarts below. The quickstart and SDK handles all of the interactions with the cloud service and implements a networking interceptor model that automatically adds an Approov token or secured API key to the required API requests. <Link href="/mobile-app-security/rasp/dynamic-cert-pinning/">Dynamic Certificate Pinning</Link> is implemented automatically. The attestation process is managed automatically, refreshing as needed. After mobile app integration, you register each app version with the Approov command line tool.
                </p>
                <Zoom>{MobileAppIntegrationImage}</Zoom>
              </div>
          </div>
        </Container>
      </Section>
      <Section>
        <Container>
          <div className="md:flex px-[10px] md:px-[29px] mt-[48px] gap-8">
              <div className="w-full md:w-[28%]">
                <h2 className="md:text-left text-center mt-0 font-bold text-[24px] md:text-[31px] text-[#1b3d57] leading-[36px] m-auto">Mobile App Quickstarts</h2>
              </div>
              <div className="w-full md:w-[72%] text-center sm:text-left">
                <p className="m-0 text-[19px] leading-normal mb-[2rem]">
                  Quickstart guides are provided for popular developer platforms below. If your platform is not listed, see <Link href="https://approov.io/docs/latest/approov-direct-sdk-integration/">Direct SDK Integration</Link> or <Link href="https://approov.io/info/contact">Contact Us</Link>
                </p>
                <QuickstartGrid tag="mobile" />
              </div>
          </div>
        </Container>
      </Section>
      {/* Blog Mobile App Attestation end */}

      <div className="mt-[14px] mb-[56px] text-center flex justify-center py-[20px]">

        {/*<Link*/}
          {/*href="/mobile-app-security/rasp/app-attestation/"*/}
          {/*className="no-underline"*/}
        {/*>*/}
          {/*<p className="text-[#86b146] mt-[20px] py-[5px] px-[23px] font-bold text-[15px] w-max text-center md:text-left not-prose rounded flex items-center">*/}
            {/*App & Device Attestation*/}
            {/*<ChevronRightIcon/>*/}
          {/*</p>*/}
        {/*</Link>*/}
      </div>
    </PageLayout>
  );
};

export default ProductOverviewPage;
